@import "App.scss";

.table-container {
  overflow-x: auto;
  width: 100%;
  margin-bottom: 50px;

  .icon-eye {
    font-size: 14px;
  }

  .gray {
    margin-right: 10px;
  }

  table {
    margin: 10px 0;
    width: 100%;

    .money {
      width: 120px;
    }

    .date {
      width: 150px;
    }

    thead {
      background-color: $color-dark-gray-blue;
      color: $color-white;
      border-radius: 4px;
      padding: 20px;

      th {
        padding: 20px;
        text-align: start;
        font-weight: 600;
        font-size: 16px;
        background-color: $color-dark-gray-blue;
        color: white !important;
      }

      tr {
        background-color: $color-dark-gray-blue;
      }
    }

    th:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    th:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    td {
      border-bottom: solid 1px $color-white-gray !important;
      padding: 15px;
      color: $color-dark-blue;
    }

    .actions {
      color: $color-gray;
      padding: 20px;

      .icon-eye {
        margin-right: 15px;
        cursor: pointer;
      }

      .icon-fileedit {
        cursor: pointer;
      }
    }
  }

  .proposition-cols {
    td {
      &:first-child {
        width: 100px;
      }

      &:nth-child(2) {
        width: 200px;
      }

      &:nth-child(3) {
        width: 200px;
      }

      &:nth-child(4) {
        width: 220px;
      }
    }
  }
}