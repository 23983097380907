@import 'App.scss';

.pagination {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  button {
    padding: 10px 14px;
    border-radius: 0;
    text-transform: none;
    background-color: $color-dark-gray-blue;
    border-color: $color-dark-gray-blue;
  }

  .prev {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
  }

  .next {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
  }

  .page-number {
    padding: 7px;
    border: $color-dark-gray-blue 3px solid;

    @media (max-width: 500px) {
      font-size: 14px !important;
    }
  }
}
