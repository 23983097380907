@import 'App.scss';

.profile {
  margin-top: 130px;
  margin-bottom: 40px;
  padding: 0 50px 0 100px;

  .email {
    cursor: pointer;
  }

  @media (max-width: 850px) {
    padding: 0 30px;
  }

  .icon-profileinfoicon {
    font-size: 28px;
    color: $color-dark-blue;
    margin-right: 10px;
  }

  .icon-edit {
    font-size: 21px;
    color: $color-gray;
    margin-left: 10px;
  }

  .icon-map {
    font-size: 22px;
    color: $color-dark-blue;
    margin-right: 10px;
  }

  .icon-greencheck {
    font-size: 10px;
    margin-right: 10px;
  }

  .info-edit-profile {
    display: flex;
    align-items: center;
  }

  .icon {
    margin-right: 15px;
  }

  .edit {
    margin-left: 15px;
    color: $color-gray;
  }

  .sub-title {
    color: $color-dark-blue;
    font-weight: 500;
    font-size: 16px;
  }

  .separator {
    height: 1px;
    width: 100%;
    background-color: $color-light-beige;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .info {
    display: flex;
    margin-bottom: 35px;

    .user-params {
      margin-bottom: 15px;
    }
  }

  .first-col {
    display: flex;
    flex-direction: column;
  }

  .second-col {
    display: flex;
    flex-direction: column;
    margin-left: 30px;

    .user-params {
      color: $color-gray;
    }

    .status {
      color: $color-green !important;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 15px;
    }
  }

  .address {
    display: flex;
    align-items: center;
  }

  .table-container {
    overflow-x: initial;
    text-align: center;

    svg {
      color: $color-gray;
    }

    .MuiPaper-root {
      background-color: transparent !important;
    }

    .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
      box-shadow: none;
    }

    .no-address {
      background: rgba(125, 142, 165, 0.17);
      border-radius: 5px;
      height: 154px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-dark-gray-blue;
      font-weight: 500;
      font-size: 16px;
    }
  }
}
