@import 'App.scss';

.finance {
  margin-top: 120px;
  margin-bottom: 40px;
  padding: 0 50px 0 100px;

  .loader-true {
    position: absolute;
    background-color: rgba(148, 148, 148, 0.671);
    width: 100%;
    height: 100%;
    z-index: 9;
    top: 0;
    left: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiCircularProgress-root {
      color: white;
      width: 80px !important;
      height: 80px !important;
      position: absolute;
    }
  }

  .pagination {
    display: flex;
    justify-content: space-between;
  }

  .loader-false {
    display: none;
  }

  @media (max-width: 850px) {
    padding: 0 20px;
  }

  h1 {
    margin: 0;

    @media (max-width: 1100px) {
      font-size: 16px;
    }
  }

  .icon {
    margin-right: 6px;
  }

  .icon-arrowdown {
    font-size: 22px;

    @media (max-width: 500px) {
      font-size: 15px;
    }
  }

  .filter-new-prop-bloc {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: solid 1px $color-light-beige;

    @media (max-width: 440px) {
      flex-direction: column;
      align-items: center;
      padding-bottom: 10px;
    }

    .beige-button {
      background-color: $color-light-brown;

      @media (max-width: 440px) {
        margin-top: 10px;
      }

      span {
        font-size: 12px;

        @media (max-width: 1100px) {
          font-size: 8px;
        }
      }

      @media (max-width: 1100px) {
        padding: 13px 13px;
      }

      .icon-plus {
        font-size: 12px;
      }
    }
  }

  .filters {
    display: flex;
    background-color: $color-dark-gray-blue-26;
    padding: 8px;
    border-radius: 4px;
    margin: 10px 0;

    .one-filter {
      margin: 10px;
      background-color: #7b8fa840;
      padding: 9px 9px 9px 16px;
      display: flex;
      align-items: center;
      border-radius: 4px;
      font-weight: 500;
      font-size: 13px;
      cursor: pointer;
      height: 30px;

      i::before {
        margin: 0;
      }

      @media (max-width: 500px) {
        padding: 6px;
        margin: 5px;
      }

      span {
        @media (max-width: 1100px) {
          font-size: 11px;
        }

        @media (max-width: 500px) {
          font-size: 10px;
        }
      }
    }
  }

  .reset-refresh {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;

    @media (max-width: 360px) {
      justify-content: space-between;
    }

    .action-btn-filter-active {
      background-color: $color-gray !important;
      color: $color-white !important;
    }

    .action-btn {
      background-color: $color-gray;
      color: $color-white;
      border: 1px solid $color-gray;
      border-radius: 5.43908px;
      padding: 8px;
      margin-left: 10px;
      cursor: pointer;

      @media (max-width: 360px) {
        padding: 5px;
        margin-left: 0;

        i {
          font-size: 10px;
        }
      }
    }

    .action-btn-filter {
      background-color: $color-white;
      color: $color-gray;
      cursor: pointer;
      padding: 6px 4px 6px 12px;

      .filter-chevron {
        font-size: 18px;
      }

      @media (max-width: 360px) {
        padding: 2px;
        margin-left: 0;

        i {
          margin: 0;
        }
      }
    }
  }

  .no-data {
    background-color: $color-dark-gray-blue-26;
    color: $color-dark-gray-blue;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;
    font-size: 14px;
    margin: 10px 0 50px 0;
    border-radius: 5px;
  }

  .legend {
    color: $color-dark-blue;
    font-weight: 500;
    font-size: 17px;
    line-height: 17px;

    .legend-list {
      margin-left: 20px;
      margin-top: 10px;
    }
  }
}

.menu {
  ul {
    padding: 0 !important;

    li:first-child {
      padding-top: 18px !important;
      padding-right: 40px !important;
    }

    li:last-child {
      padding-bottom: 18px !important;
      padding-right: 40px !important;
    }

    li {
      font-size: 12px !important;
      padding: 9px 20px !important;
    }
  }
}