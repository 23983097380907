@import 'App.scss';

.logged-out-navbar-container-blue {
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    top: 0;
    z-index: 1;
    background-color: $color-dark-blue;
}

.logged-out-navbar-container {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    z-index: 2;
}

.links-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    color: $color-white;
}

.links-container-logged-in {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    color: $color-white;
}

.links {
    display: flex;
    align-items: center;
    width: 70%;
}

.links-logged-in {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    .link {
        margin-right: 30px;
    }
}

.link {
    margin-right: 10%;
    font-size: 16px;
    color: $color-white;
    font-weight: 500;
}

.accueil {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.chevron-accueil {
    margin-left: 10px;
    font-size: 10px;
}

.menu-button {
    color: $color-beige;
    width: 30px;
    height: 30px;
}

.drawer-bloc {
    display: none;
}

.drawer-bloc-logged-in {
    display: none;
}

.link-list a {
    color: $color-white;
}

.MuiPaper-root {
    background-color: $color-dark-blue !important;
}

.MuiMenu-list {
    background-color: $color-white !important;
}

#demo-positioned-menu a {
    color: $color-gray
}

.dropdown-menu {
    display: flex;
    align-items: center;
}

.nav-button {
    font-size: 16px;
}

.menu-item {
    color: $color-gray !important;

    .icon {
        margin-right: 15px;
    }
}

.icon-profileinfoicon {
    font-size: 25px;
}

.logout {
    color: $color-white
}

@media (max-width: 1500px) {
    .link {
        font-size: 12px;
    }

    .chevron-accueil {
        font-size: 8px;
    }

    .nav-button {
        font-size: 13px;

        .button {
            padding: 10px 23px;
        }
    }

    .links-container {
        .button {
            padding: 10px 23px;
        }
    }
}

@media (max-width: 1510px) {
    .links-container-logged-in {
        .button {
            padding: 10px 23px;
        }
    }

    .links-logged-in {
        font-size: 12px;
    }
}


@media (max-width: 1245px) {
    .links-container-logged-in {
        .button {
            padding: 7px 20px;
        }
    }

    .chevron-accueil {
        font-size: 4px;
    }

    .links-container-logged-in .link {
        font-size: 9px;
    }
}

@media (max-width: 1188px) {
    .link {
        font-size: 9px;
    }

    .nav-button {
        font-size: 10px;
    }

    .links-container {
        .button {
            padding: 7px 20px;
        }
    }

    .logo {
        width: 300px;
    }
}

@media (max-width:1050px) {
    .links-logged-in {
        display: none;
    }

    .links-container-logged-in {
        .button {
            display: none;
        }
    }

    .logo {
        width: 250px;
    }

    .drawer-bloc-logged-in {
        display: block;
        width: 50px;
    }
}

@media (max-width: 971px) {
    .links {
        display: none;
    }

    .links-container {
        .button {
            display: none;
        }
    }

    .drawer-bloc {
        display: block;
        width: 50px;
    }

    .logo {
        width: 250px;
    }
}

@media (max-width: 450px) {
    .logo {
        width: 180px;
    }
}