@import 'App.scss';

.agent-menu-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 9px 16px 9px 16px;

  label {
    display: none;
  }

  input {
    padding: 15px !important;
    border: 1px solid $color-dim-gray;
    border-radius: 4px;
    outline: none;
    font-size: 14px;

    &:focus {
      outline: 1px solid $color-gray-blue;
    }

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &::placeholder {
      color: $color-gray;
      font-weight: 400;
      font-size: 14px;
    }
  }

  button {
    margin-top: 17px;
    text-transform: none;
    font-weight: 500;
    font-size: 17px;
    padding: 12px 16px;
  }
}
