@import 'App.scss';

.register-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0% 35%;
  margin-top: 130px;
  margin-bottom: 30px;

  label:after {
    content: ' *';
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.register-directions {
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 10px;
}

.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled {
  color: $color-dim-gray !important;
}

.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root,
.css-gztimk-MuiPaper-root-MuiPickersPopper-paper,
.css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel,
.css-1ae9t7h-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button,
.css-jro82b-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button,
.css-12mkn7b-MuiButtonBase-root-MuiIconButton-root-MuiPickersCalendarHeader-switchViewButton {
  color: $color-dark-blue !important;
}

.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  color: $color-white !important;
}

.birthday {
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  .MuiPickersDay-root {
    color: $color-dark-blue;
  }

  .gray {
    margin-bottom: 10px;
    font-size: 14px;
    color: $color-gray;
    font-weight: 600;
  }
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 1px solid $color-dim-gray !important;
}

.Mui-selected {
  background-color: $color-beige !important;
  color: $color-white;
  border: unset !important;
}

.conditions {
  color: $color-gray;
  font-size: 13px;
  margin-left: 10px;
}

.checking-conditions {
  display: flex;
  align-items: flex-start;
  margin-top: 35px;
  margin-bottom: 20px;
  width: 400px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;

  .MuiCircularProgress-root {
    color: white;
    width: 20px !important;
    height: 20px !important;
    position: absolute;
    margin-right: 230px;
  }
}

.error-message {
  color: $color-red;
  margin-top: 10px;
}

.error-true {
  .MuiFormControl-root {
    border-radius: 4px;
    outline: 1px solid $color-red !important;
  }
}

.accept {
  font-weight: 600;
}

.got-account {
  color: $color-gray;
  text-align: center;
  margin-top: 20px;
}

.MuiCalendarPicker-root {
  background-color: $color-white;
}

@media (max-width: 1400px) {
  .register-container {
    padding: 0% 30%;
  }
}

@media (max-width: 990px) {
  .register-container {
    padding: 0% 20%;
  }
}

@media (max-width: 650px) {
  .register-container {
    padding: 0% 10%;
  }

  .checking-conditions {
    width: auto;
  }
}
