@font-face {
    font-family: Metropolis;
    src: url("./Metropolis/Metropolis-Regular.otf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Metropolis;
    src: url("./Metropolis/Metropolis-Medium.otf");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: Metropolis;
    src: url("./Metropolis/Metropolis-SemiBold.otf");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: Metropolis;
    src: url("./Metropolis/Metropolis-Bold.otf");
    font-weight: 700;
    font-style: normal;
}