@import 'App.scss';

.add-req {
  margin-top: 120px;
  margin-bottom: 40px;
  padding: 0 50px 0 100px;
  display: flex;
  flex-direction: column;

  .state {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-dark-blue !important;
  }

  .bread {
    width: 100%;
  }

  .status {
    font-size: 18px;

    &::before {
      content: '*';
      color: rgba(0, 0, 0, 0);
    }

    @media (max-width: 350px) {
      font-size: 12px;
    }
  }

  .loader-true {
    position: absolute;
    background-color: rgba(148, 148, 148, 0.671);
    width: 100%;
    height: 100%;
    z-index: 9;
    top: 0;
    left: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiCircularProgress-root {
      color: white;
      width: 80px !important;
      height: 80px !important;
      position: absolute;
    }
  }

  .loader-false {
    display: none;
  }

  .MuiDialog-paper {
    background-color: $color-white !important;
  }

  .full-width {
    display: flex;
    align-items: center;

    @media (max-width: 660px) {
      flex-direction: column;
    }
  }

  .buttons-container {
    display: flex;
  }

  .title-container {
    display: flex;
    justify-content: space-between;

    h1 {
      margin: 0;
    }

    .edit-button-container {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      .edit-button {
        border: 1px $color-dark-gray-blue solid;
        border-radius: 4px;
        background-color: $color-dark-gray-blue;
        color: $color-white;
        height: 30px;
        width: 120px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
      }
    }
  }

  @media (max-width: 850px) {
    padding: 0 50px;
  }

  @media (max-width: 410px) {
    padding: 0 20px;
  }

  @media (max-width: 465px) {
    margin-top: 110px;

    h1 {
      font-size: 18px;
      margin-bottom: 0;
    }
  }

  .blue-title {
    padding: 20px;
    background-color: $color-dark-gray-blue;
    color: $color-white;
    margin: 12px 0;
    border-radius: 4px;
    font-weight: 600;
  }

  .poNumber {
    padding: 15px;
    color: $color-gray;
    background-color: #fafafa;
    border-radius: 4px;
    border: 1px solid $color-dim-gray;
    margin: 12px 0;
    font-weight: 600;
    font-size: 22px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .one-file {
      font-size: 17px;
      margin-left: 15px;
      color: blue;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .alone-input {
    .input {
      width: 100% !important;
    }
  }

  .input {
    width: 48%;

    .select-placeholder-selected {
      .MuiSelect-select {
        color: $color-black;
      }
    }

    .select-placeholder {
      .MuiSelect-select {
        color: $color-gray;
      }
    }

    @media (max-width: 700px) {
      width: 100%;
    }

    .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
      height: 45px;
    }
  }

  .user-info-bloc,
  .subject-bloc,
  .req-bloc,
  .credit,
  .zone-bloc,
  .solo-input {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;

    @media (max-width: 700px) {
      flex-wrap: nowrap;
      flex-direction: column;
    }
  }

  .separator {
    width: 100%;
    height: 1px;
    background-color: $color-white-gray;
    margin: 15px 0;
  }

  .ten-percent {
    color: $color-dark-gray-blue;
    background-color: $color-dark-gray-blue-26;
    font-weight: 500;
    font-size: 12px;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
  }

  textarea {
    border: 1px solid $color-dim-gray;
    border-radius: 4px;
    height: 150px;
    resize: none;
    padding: 20px;
    color: $color-gray;
    outline: none;
    width: 100%;
    margin-top: 10px;
  }

  textarea::placeholder {
    color: $color-dim-gray;
  }

  select {
    border: 1px solid $color-dim-gray;
    border-radius: 4px;
    padding: 15px;
    color: $color-gray;
    outline: none;
  }

  .button {
    color: $color-white;
    font-weight: 400;
    font-size: 17px;
    text-transform: none;
    border: none;
    margin-right: 20px;

    .MuiCircularProgress-root {
      color: white;
      width: 20px !important;
      height: 20px !important;
      position: absolute;
    }
  }

  .btns {
    margin-top: 20px;
    display: flex;

    .send {
      background-color: $color-light-brown;
    }

    .cancel {
      background-color: $color-gray-blue;
    }

    @media (max-width: 365px) {
      width: 100%;
      display: flex;
      justify-content: space-around;

      .button {
        font-size: 13px;
        margin: 0;
      }
    }
  }
}