@import "App.scss";

.about {
    margin-top: 130px;
    margin-bottom: 40px;
    padding: 0 4%;

    .title-skeleton {
        height: 50px !important;
        width: 130px;
    }

    .green-skeleton {
        height: 70px !important;
        width: 290px;
    }

    .wave-bloc {
        margin: 15px 0;
    }

    .image-text-bloc {
        display: flex;
        width: 100%;

        .text-bloc {
            width: 70%;
        }

        .image-skeleton {
            display: flex;
            align-items: flex-start;
            margin-left: 30px;
            border-radius: 25px !important;
        }

        @media (max-width: 1024px) {
            flex-direction: column-reverse;

            img,
            .image-skeleton {
                width: 100% !important;
                object-fit: cover;
                margin: 20px 0;
                border-radius: 20px;
            }

            .text-bloc {
                width: 100%;
            }
        }
    }
}