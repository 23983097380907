@import 'App.scss';

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  width: 100%;
  flex-direction: column;
  margin-bottom: 30px;
  height: 100vh;

  .MuiCircularProgress-root {
    color: white;
    width: 20px !important;
    height: 20px !important;
    position: absolute;
    margin-right: 150px;
  }
}

.input {
  display: flex;
  flex-direction: column;
  margin: 10px 0;

  label {
    margin-bottom: 10px;
    font-size: 14px !important;
    color: $color-gray !important;
    font-weight: 600 !important;
  }

  input {
    padding: 15px !important;
    border: 1px solid $color-dim-gray;
    border-radius: 4px;
    outline: none;
    font-size: 14px;

    &:focus {
      outline: 1px solid $color-gray-blue;
    }

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &::placeholder {
      color: $color-gray;
      font-weight: 400;
      font-size: 14px;
    }
  }

  .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
    color: $color-dim-gray !important;
  }

  .error-message {
    color: $color-red;
    margin-top: 10px;
  }

  .error-true {
    outline: 1px solid $color-red;
  }

  .required {
    &::after {
      content: ' *';
    }
  }
}

.pass-input-container {
  position: relative;
  margin-top: 25px;
}

.eye {
  position: absolute;
  right: 10px;
  top: 37px;
  font-size: 22px;
  cursor: pointer;
}

.forgot {
  font-size: 13px;
  font-weight: 600;
}

.got-account {
  font-size: 13px;
}

.stay-connected {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;

  .check {
    width: 20px;
    margin-right: 15px;
  }
}

.stay-connected-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}

.login {
  .button {
    width: 100%;
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 500px) {
  .login {
    .button {
      width: 280px;
    }

    input {
      width: 280px;
    }
  }

  span {
    font-size: small;
  }
}

@media (max-width: 400px) {
  .dark-blue {
    width: auto;
  }
}
