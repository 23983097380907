@import 'App.scss';

.forgot-pass {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    padding: 0 35%;

    @media (max-width: 1240px) {
        padding: 0 30%
    }

    @media (max-width: 930px) {
        padding: 0 22%
    }

    @media (max-width: 930px) {
        padding: 0 10%
    }

    @media (max-width: 450px) {
        padding: 0 4%
    }

    h1 {
        text-align: start;
        width: 100%;

        @media (max-width: 450px) {
            font-size: 18px;
        }
    }

    .input {
        width: 100%;
    }

    .beige-button {
        padding: 13px;
        margin-top: 20px;
        width: 100%;

        @media (max-width: 450px) {
            span {
                font-size: 10px;
            }
        }
    }

    .back {
        color: $color-dark-blue;
        margin-top: 20px;
        font-weight: 500;
        font-size: 14px;
        width: 100%;
    }
}