@import 'App.scss';

.contact {
  margin-top: 130px;
  margin-bottom: 40px;
  padding: 0 4%;

  .bread {
    padding-top: 0;
  }

  h1 {
    margin-bottom: 20px;
  }

  input {
    color: $color-black;
    outline: none;
  }

  .comment-input {
    margin: 20px 0 !important;
  }

  .name {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .input {
      width: 49%;
    }

    @media (max-width: 600px) {
      flex-direction: column;

      .input {
        width: 100%;
      }
    }
  }

  .alone-input {
    .input {
      width: 100% !important;
    }
  }

  .MuiInputBase-root {
    height: 46px;
  }

  select {
    border: 1px solid $color-dim-gray;
    border-radius: 4px;
    padding: 15px;
    color: $color-gray;
    outline: none;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    padding: 15px;
    background-image: url('../../assets/select-arrow.png');
    background-repeat: no-repeat;
    background-position: calc(100% - 20px);
    background-size: 8px;
  }

  textarea {
    border: 1px solid $color-dim-gray;
    border-radius: 4px;
    font-size: 14px;
    height: 150px;
    resize: none;
    padding: 20px;
    color: $color-black;
    outline: none;

    &:focus {
      outline: 1px solid $color-gray-blue !important;
    }

    &::placeholder {
      font-size: 16px;
    }
  }

  .button {
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;

    .MuiCircularProgress-root {
      color: white;
      width: 20px !important;
      height: 20px !important;
      position: absolute;
      margin-right: 150px;
    }

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  .select-placeholder-selected {
    .MuiSelect-select {
      color: $color-black;
    }
  }

  .select-placeholder {
    .MuiSelect-select {
      color: $color-gray;
    }
  }

  .error-true {
    outline: 1px solid $color-red !important;
  }
}
