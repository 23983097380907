@import './assets/fonts/fonts';

$color-green: #79c585;
$color-red: #ff0000;
$color-light-beige: #f6f1e9;
$color-beige: #d9c49f;
$color-light-brown: #c5a779;
$color-gray-blue: #abafc2;
$color-dark-gray-blue: #7d8ea5;
$color-dark-gray-blue-26: #7d8ea526;
$color-dark-blue: #002b4c;
$color-white: #ffffff;
$color-white-gray: #e9ecf0;
$color-dim-gray: #e3e6ef;
$color-light-gray: #b3b3b3;
$color-gray: #7d8e90;
$color-black: #000000;
$color-silver: #d9d9d9;

.app-container-logged-id-true {
  margin-left: 250px;
}

.button {
  border-radius: 5px;
  padding: 13px 26px;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  text-transform: uppercase;
}

.disabled {
  filter: brightness(0.95);
  pointer-events: none;
}

.d-flex {
  display: flex;
}

.items-center {
  align-items: center !important;
}

.full-width {
  width: 100%;
}

.wrap {
  flex-wrap: wrap;
}

.login-button {
  border: 1px solid $color-white;
  color: $color-white;
  background-color: unset;
}

.beige-button {
  border: 1px solid $color-beige;
  color: $color-white;
  background-color: $color-beige;
  letter-spacing: 2px;
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.footer {
  width: 100%;
  background-color: $color-dark-blue;
  color: $color-white;
  padding: 15px 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

a {
  text-decoration: none;
  color: $color-white;
}

.bold {
  font-weight: bold;
}

.dark-blue {
  color: $color-dark-blue;
}

.gray {
  color: $color-gray;
}

.light-gray {
  color: $color-light-gray;
}

.beige {
  color: $color-beige;
  text-transform: uppercase;
  font-size: 20px;
}

h1 {
  font-size: 24px;
  color: $color-dark-blue;
  text-transform: uppercase;
  font-weight: 500;
}

.check {
  width: 20px;
  height: 20px;
  accent-color: $color-dark-blue;
}

@media (max-width: 450px) {
  .button {
    font-size: 12px;
  }
}

@media (max-width: 850px) {
  .app-container-logged-id-true {
    margin-left: 0;
  }
}

.bread {
  padding: 20px 0;
  .bread-link {
    color: $color-gray-blue;
  }
}
