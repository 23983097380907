@import "App.scss";

.road-map{
    padding: 70px;
}

.card{
    width: 511px;
}

.card-texts{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.text{
    padding: 5px;
}

.road-map-title{
    text-transform: uppercase;
    font-size: 24px !important;
}

.first-bloc{
    margin-top: 35px;
    display: flex;
    justify-content: space-between;
}

.arrow-down{
    margin-left: 250px;
}

.second-bloc{
    display: flex;
    justify-content: space-around;
}

.third-bloc{
    display: flex;
    align-items: flex-end;
    margin-left: 50px;
}

.last-pic{
    margin-bottom: 50px;
}

.road-map-phone-vue{
    display: none;
}

.image-roadmap{
    font-size: 100px;
    color: $color-dark-blue;
}

@media (max-width: 1220px) {
    .card{
        .beige{
            font-size: 15px;
        }
        .dark-blue{
            font-size: 12px;
        }
        .image-roadmap{
            font-size: 80px;
        }
    }
    .arrow-right,.arrow-left{
        width: 220px;
    }
    .arrow-down{
        height: 160px;
    }
    .last-pic{
        width: 280px;
        height: 170px;
        margin-bottom: 40px;
    }
}

@media (max-width: 880px) {
    .road-map{
        padding: 20px;
    }
    .card{
        .beige{
            font-size: 12px;
        }
        .dark-blue{
            font-size: 10px;
        }
        .image-roadmap{
            font-size: 50px;
        }
    }
    .arrow-right,.arrow-left{
        width: 180px;
    }
    .arrow-down{
        height: 120px;
    }
    .last-pic{
        width: 240px;
        height: 130px;
        margin-bottom: 20px;
    }
}

@media (max-width: 700px) {
    .road-map{
        padding: 15px;
    }
    .card{
        .beige{
            font-size: 9px;
        }
        .dark-blue{
            font-size: 8px;
        }
        .image-roadmap{
            font-size: 35px;
        }
    }
    .arrow-right,.arrow-left{
        width: 140px;
    }
    .arrow-down{
        height: 80px;
    }
    .last-pic{
        width: 200px;
        height: 90px;
        margin-bottom: 10px;
    }
    .card-texts{
        height: 50px;
    }
}

@media (max-width: 500px) {
    .road-map-title{
        margin-bottom: 20px;
    }
    .road-map-phone-vue{
        display: flex;
        flex-direction: column;
        padding: 30px;
    }
    .road-map{
        display: none;
    }
    .card{
        align-items: center;
        width: 400px;
        .beige{
            font-size: 15px;
        }
        .dark-blue{
            font-size: 12px;
        }
        .image-roadmap{
            font-size: 80px;
        }
    }
    .arrow-down{
        height: 60px;
        margin: 30px 0;
    }
    .arrow-down-second{
        height: 80px;
        margin: 25px ;
    }
    .blocs{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 400px) {
    .card{
        width: 320px;
    }
    .arrow-down{
        height: 60px;
        margin: 30px 0;
    }
    .arrow-down-second{
        height: 80px;
        margin: 25px ;
    }
    .dark-blue{
        width: 200px;
    }
    .beige{
        width: 200px;
    }
    .image-roadmap{
        margin-left: 15px;
    }
}