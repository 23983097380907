@import "App.scss";

.edit-profile {
    margin-top: 130px;
    margin-bottom: 40px;
    padding: 0 50px 0 100px;

    @media (max-width: 850px) {
        padding: 0 30px 0 30px;
    }

    .profile-info-bloc {
        display: flex;
        flex-wrap: wrap;
        background-color: $color-dark-gray-blue-26;
        border-radius: 4px;
        margin: 0 0 20px 0;

        .label {
            color: $color-gray;
            font-weight: 600;
            font-size: 15px;
            margin-bottom: 10px;
        }

        .input {
            width: 400px;
            margin: 20px 50px 10px 20px;

            @media (max-width: 850px) {
                width: 250px;
                margin: 20px 30px 10px 20px;
            }
        }

        .birthday {
            width: 400px;
            margin: 20px 50px 20px 20px;

            .MuiInputLabel-root{
                display: none;
            }

            @media (max-width: 850px) {
                width: 250px;
            }
        }

        .MuiInputBase-root {
            background-color: $color-white;
        }
    }

    .dark-blue {
        text-decoration: underline;
        font-weight: 600;
    }

    .data-protection {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        padding: 20px;
        margin: 20px 0;

        .conditions {
            color: $color-black;
            width: fit-content;
        }

        .check-box {
            width: 25px;
            height: 25px;
        }

        .check {
            height: 25px;
            width: 25px;
            border-radius: 4px;
            background-color: $color-white;
            vertical-align: middle;
            border: 2px solid $color-dim-gray;
            appearance: none;
            cursor: pointer;
        }

        .check:checked {
            background-image: url("../../assets/check.svg");
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    .pass {
        margin-top: 20px;
    }

    .new-pass {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
        width: 100%;
    }

    .buttons {
        margin-bottom: 30px;
        width: 100%;
        display: flex;

        .button {
            color: $color-white;
            font-weight: 400;
            font-size: 17px;
            text-transform: none;
            border: none;
            margin-right: 20px;

            .MuiCircularProgress-root {
                color: white;
                width: 20px !important;
                height: 20px !important;
                position: absolute;
                margin-right: 150px;
            }

            @media (max-width: 380px) {
                margin: 0;
                font-size: 14px;
            }
        }

        @media (max-width: 380px) {
            display: flex;
            justify-content: space-between;

            .button {
                margin: 0;
            }
        }
    }



    .save {
        background-color: $color-light-brown;
    }

    .cancel {
        background-color: $color-gray-blue;
    }

    .required {
        color: $color-dark-blue;
        font-weight: 500;
        font-size: 12px;
    }
}