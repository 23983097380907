@import 'App.scss';

.sidebar {
    margin: 110px 25px 0 45px;
    background: $color-white;
    box-shadow: 0px 0px 12px 1px rgba(125, 140, 142, 0.15);
    border-radius: 5px;
    width: 285px;
    padding: 25px 25px 0 25px;
    position: fixed;

    .my-account {
        font-weight: 600;
        font-size: 16px;
    }

    .sidebar-link {
        display: flex;
        align-items: center;
        margin: 25px 0;
        text-transform: none;

        span {
            font-weight: 500;
            font-size: 15px;
        }

        .icon {
            margin-right: 15px;
            width: 25px;
            height: 25px;
        }
    }

    @media (max-width: 850px) {
        display: none;
    }
}