@import 'App.scss';

.hero-bg {
    width: 100%;
    height: 980px;
    object-fit: cover;
}

.home-screen-titles {
    position: absolute;
    z-index: 1;
    color: $color-white;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 20%;
    padding: 0 50px;
    top: 0;
}

.h1 {
    font-size: 101px;
    text-align: center;
    color: $color-white;
    font-weight: 700;
    font-style: normal;
    text-transform: none;
}

.h2 {
    font-size: 24px;
    text-align: center;
    line-height: 24px;
    margin: 40px 10% 0 10%;
    font-weight: 500;
}

.home-buttons {
    margin-top: 4%;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 30%;
}

.register {
    margin-right: 10%;
}

.arrow-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 1;
    bottom: -15vh;
    cursor: pointer;
}

.arrow {
    margin-bottom: 10px;
    animation: pointing 2s ease-in-out infinite;
}

@keyframes pointing {
    0% {
        margin-bottom: 15px;
    }

    50% {
        margin-bottom: 2px;
    }

    100% {
        margin-bottom: 15px;
    }
}

@media (max-width: 1140px) {
    .main-title {
        font-size: 80px;
    }
}

@media (max-width: 600px) {
    .home-screen-titles {
        padding: 0 40px;
    }

    .h1 {
        font-size: 50px;
        margin-top: 20%;
    }

    .h2 {
        font-size: 15px;
        margin: 20% 0;
    }

    .home-buttons {
        justify-content: space-around;
    }
}