@import 'App.scss';

.delete-modal{
  .MuiPaper-root{
    background-color: $color-white !important;
  }
}


.MuiDialog-paper {
  background-color: $color-white !important;
  width: 531px;
  height: 210px;

  .MuiCircularProgress-root {
    color: $color-red !important;
    width: 10px !important;
    height: 10px !important;
    position: absolute;
    left: 2px;
  }

  @media (max-width: 540px) {
    height: 260px;
  }

  h2 {
    padding-top: 0;
    padding-left: 20px;
    padding-bottom: 30px;
    padding-right: 20px;

    @media (max-width: 540px) {
      padding-right: 25px;
      line-height: 20px;
    }
  }

  p {
    font-family: 'Metropolis';
    color: #001329;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
  }

  .MuiDialogContent-root {
    padding-left: 20px;
    padding-bottom: 0;
    padding-right: 33px;
  }

  #alert-dialog-title {
    display: flex;
    justify-content: space-between;

    span {
      color: $color-dark-blue;
      margin-top: 20px;
      font-weight: 500;
      font-size: 17px;
    }

    .icon-plus {
      margin: 8px 0 0 0;
    }

    i {
      transform: rotateZ(45deg);
      font-size: 12px;
      color: $color-gray;
    }
  }

  .button {
    border-radius: 4px;
    padding: 12px 16px;
    margin-bottom: 20px;
    text-transform: none;
    font-weight: 500;
    width: 144px;
    font-size: 17px;

    @media (max-width: 540px) {
      font-size: 14px;
      margin-bottom: 5px;
      padding: 12px 8px;
    }
  }

  .delete-button {
    border: 1px $color-red solid;
    background-color: $color-white;
    color: $color-red;
    position: relative;
  }

  .gray-button {
    border: unset;
    background-color: $color-gray-blue;
    color: $color-white;
    margin-right: 20px;
  }
}

.modal-button {
  margin-left: 10px;
  height: 30px;
  width: 120px;
  border: 1px $color-red solid;
  color: $color-red;
  border-radius: 4px;
  background-color: $color-white;
  cursor: pointer;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  max-width: 90vw;
  background-color: $color-white;
  border-radius: 5.5px;
  padding: 20px;

  &:focus-visible {
    outline: none;
  }

  .icon-cross {
    color: $color-silver;
    float: right;
    cursor: pointer;

    &:hover {
      color: $color-light-gray;
    }
  }

  h2,
  p {
    font-family: 'Metropolis';
  }

  .error-button {
    float: right;
    margin-top: 1rem;
  }
}
